.login-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  min-height: 100vh;
  img[alt="Bonjour"] {
      max-width: 490px;
  }
  .form {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      min-height: 500px;
      max-width: 320px;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
          margin: 0 auto 30px;
          width: 90%;
          max-width: 140px;
      }
      label {
          flex: 1;
          text-align: left;
          color: #333D48;
          font-size: 11px;
          font-weight: bold;
          line-height: 15px;
          margin-bottom: 10px;
      }
      input {
        border: 1px solid #E0E7FF;
        border-radius: 5px;
        background-color: rgba(224,231,255,0.2);
        color: #2E384D;
      }
      .password-row {
          margin-top: 20px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          label {
              margin-bottom: 0;
          }
      }
      a {
        flex: 1;
        text-decoration: none;
        color: #D75F77;
        font-size: 11px;
        line-height: 22px;
        text-align: right;
      }
      button {
          flex: 1;
          display: flex;
          align-self: stretch;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;
          justify-content: center;
          border-radius: 20px;
          background-color: #D75F77;
          padding: 10px;
          margin-top: 70px;
          cursor: pointer;
          border: none;
          outline: none;
      }

      .checkbox {
          width: 100%;
          display: block;
          position: relative;
          padding-left: 30px;
          margin-bottom: 12px;
          margin-top: 20px;
          cursor: pointer;
          user-select: none;
          color: #888C91;
          font-size: 11px;
          line-height: 22px;

          input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
          }
          .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 20px;
              border: 1px solid #E0E7FF;
              border-radius: 5px;
              background-color: rgba(224,231,255,0.2);
              color: #2E384D;
              &:after {
                  content: "";
                  position: absolute;
                  display: none;
                  left: 7px;
                  top: 3px;
                  width: 5px;
                  height: 10px;
                  border: solid #4D9CF4;
                  border-width: 0 2px 2px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
              }
          }
          &:hover input ~ .checkmark {
              background-color: rgba(224,231,255,0.4);
          }
          input:checked ~ .checkmark {
              background-color: rgba(224,231,255,0.4);
          }
          input:checked ~ .checkmark:after {
              display: block;
          }
      }
  }
  @media (max-width: 1200px) {
      img[alt="Bonjour"] {
          display: none;
      }
  }
}