.NavBarDisplay {
  color: white;
  display: none;
  height: 50px;
  padding: 0px 20px;
  
  &__icon{
    position: relative;
    top: -5px;
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .NavBarDisplay {
    position: fixed;
    top: 0px;
    width: 100%;
    display: block;
  }
}