.ClasseStudents{
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  &__title{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #333D48;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;

    & > div {
      background-color: #F0F4F8;
      box-sizing: border-box;
      width: 35px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      color: #7494C7;
      position: relative;
    }
  }

  &__button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      border: 1px solid #4D9CF4;
      border-radius: 15px;
      color: #4D9CF4;
      font-size: 12px;
    }
  }

  &__list{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .checkbox-name {
      &:not(.isRegisteredAtClass) {
        color: #888C91
      }
      i {
        color: #4D9CF4;
      }
    }
    div {
      margin-bottom: 10px;
      font-size: 12px;
    }
    .ant-checkbox-group {
      label {
        width: 100%;
      }
    }
    .SpinnerArea {
      margin: 20px 0 0 0;
    }
  }
}