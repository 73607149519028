
.InputEmail{
  width: 100%;
  margin-top: 15px;
  label{
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    position: relative;
    top: -10px;
  }
}