.ModalClosable{
  &__body{
    position: relative;

    &__close{
      background-color: transparent;
      border: none;
      position: absolute;
      top: -60px;
      right: -60px;
      font-size: 25px;
      color: white;
      cursor: pointer;
    }
  }
}