.Dashboard{
  height: calc(100vh - 72px);
  padding: 0 30px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .CardTable {
    &.absences, &.delays {
        .ant-table-content {
            max-height: 244px;
            min-height: 244px;
            overflow: auto;
        }   
    }
  }
  & > .ant-row {
    flex: 1;
    display: flex;
    margin-bottom: 20px;
    &.top-row {
      .inside {
        max-width: calc(50% - 10px);
        .ant-card-body {
          max-height: 340px;
          overflow: auto;
        }
      }
    }
    .Card {
      box-shadow: none;
      .ant-card-head {
        box-shadow: none;
        border: none;
      }
      .ant-card-body {
        padding: 0 20px 20px;
      }
    }
    .inside {
      background: white;
      & + .inside {
        margin-left: 20px;
      }
    }
  }
}