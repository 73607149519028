
@media print {
    .ant-layout-header,
    .ClasseDetail__breadcrumb,
    .FixedAvatar,
    .MenuDisplay__logo,
    .ClasseEvent,
    .ant-tabs-nav,
    .ClasseStudents,
    .ant-tabs-nav-list,
    .GroupCalendar .head,
    .ant-btn {
      display: none;
      visibility: hidden;
      max-height: 0;
    }
    .Card {
      box-shadow: none;
      border: none !important;
    }
    .ClasseEvent {
      min-height: 0 !important;
    }
    .ClasseDetail__panel {
      padding-right: 0 !important;
    }
    @page { margin: 0; }
  }