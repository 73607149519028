
.LoadingPage{
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img{
    margin-bottom: 50px;
  }
}