@import '../../../../node_modules/react-week-calendar/dist/style.css';

.WeeklyCalendar{
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
  border-radius: 6px;
  border: none;
}
.weekCalendar {
  z-index: 1;
  background: white;
  &__header {
    line-height: 20px;
  }
  &__scaleHeader {
    border: 1px solid #F1F2F4;
    border-bottom: none !important;
    background: transparent !important;
  }
  &__scaleHeader, &__headerWrapper, &__scaleCell {
    background: white;
    color: #333D48;
    font-family: "Euphemia", sans-serif;
    font-weight: bold;
    font-size: 11px;
    line-height: 20px;
  }
  &__scaleCell {
    border-bottom: none;
    color: #888C91;
    &:nth-child(4n-1), &:nth-child(4n-2), &:nth-child(4n-4) {
        color: transparent;
    }
    &:nth-child(4n-3) {
      transform: translateY(-50%);
    }
  }
  &__scaleColumn {
    border-left: none;
  }
  &__headerColumn {
    border: 1px solid #F1F2F4;
    border-top: none;
    border-bottom: none;
    border-left: none;
    text-transform: capitalize;
  }
  &__headerWrapper, &__scaleColumn, .calendarBody__cell, .calendarBody__column {
    border-color: #F1F2F4;
  }
  .calendarBody__cell {
    &:nth-child(4n-1), &:nth-child(4n-2), &:nth-child(4n-3) {
      border-bottom-style: dashed;
      border-bottom-color: rgba(241, 242, 244, 0.3);
    }
  }
  .event {
    background-color:  white;
    border: 1px solid #D7D9DC;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0);
    padding: 10px;
    color: #888C91;
    &.multiple {
      label {
        color: rgba(0,0,0,.5);
      }
    }
    label {
      color: inherit;
      font-family: "Euphemia", sans-serif;
      font-size: 11px;
      line-height: 15px;
      display: block;
      margin-bottom: 5px;
      &.bold {
        font-family: "Euphemia", sans-serif;
        font-weight: bold;
        display: block;
      }
    }
    &.lock:not(.multiple) {
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .25);
      }
      label {
        color: rgba(0,0,0,.5);
      }
      border: none;
    }
    .Flags, i {
      position: absolute;
      top: 0;
      right: 5px;
      img {
        width: 15px;
        height: 15px;
      }
    }
    i {
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      z-index: 1;
      font-size: 20px;
      margin-top: 7px;
    }
  }
  .dayCell:hover, &__overlay_status_selection {
    cursor: pointer;
    background-color: rgba(199, 199, 199, 0.2);
    border-color: transparent;
  }
}
.remove-lock {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > i {
    color: #DB8711;
    font-size: 40px;
    margin: 10px 0;
  }
  & > label {
    font-size: 20px;
    max-width: 400px;
    text-align: center;
    margin-bottom: 20px;
  }
  .ant-radio-group {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    .ant-radio-wrapper {
      display: block;
      margin: 10px 0;
    }
  }
}