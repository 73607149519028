.MaterialTable {
    .n-of-n {
        margin-top: 20px;
    }
    .ant-table-thead {
        tr th {
            background: #809db3;
        }
        tr th, .ant-table-column-sorter {
            color: white;
        }
    }
}