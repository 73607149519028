.FormHolidays {
    .left-panel, .year-control {
        padding-right: 30px;
    }
    label {

        margin-top: 4px;
        display: block;
        font-family: EuphemiaUCAS, sans-serif;
        font-size: 14px;
        color: #333d48;
    }
    .ant-tabs {
        max-width: 700px;
        margin-bottom: 20px;
        .ant-tabs-tab-btn {
            .anticon {
                margin-left: 5px;
                margin-top: -5px;
                color: #d75f77;
            }
        }
    }
    .ant-tabs-top > .ant-tabs-nav {
        margin: 0;
    }
    .ant-picker-calendar {
        .ant-picker-calendar-header {
            display: none;
        }
    }
}