.CardTitle {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-content: center;
    .AdvancedRange {
        margin-left: 15px;
    }
    .ant-row {
        display: flex;
        flex-direction: row;
    }
    h3 {
        color: #333D48;
        font-weight: bold;
        font-size: 14px;
        line-height: 30px;
        margin-right: 20px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .ant-select-selection--single, .ant-radio-button-wrapper {
        height: 30px;
    }
    .ant-radio-button-wrapper {
        font-size: 11px;
        font-weight: bold;
        line-height: 26px;
        &-checked {
            border-radius: 3px 0 0 3px;
            background-color: #4D9CF4;
            color: white;
        }
    }
}