
.CalendarHourWeek{
  display: flex;
  flex-direction: row;
  &__hour{
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid #F1F2F4;
  }
  &__week{
    display: flex;
    flex-direction: row;
    flex: 1;

    &__day{
      flex: 1;
      border: 1px solid #F1F2F4;
      padding: 10px 5px;
      box-sizing: border-box;
    }
  }

  &__days{
    flex: 1;
    border: 1px solid #F1F2F4;
    padding: 10px 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &__day{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

.CalendarHourWeekDay{
  display: flex;
  flex-direction: row;
}
