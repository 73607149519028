
.CalendarMoreClass{
  height: 25px;
  width: 100%;
  border: 1px solid #4D9CF4;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4D9CF4;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover{
    background-color: #4D9CF4;
    color: white;
    transition-duration: 0.3s;
  }
}