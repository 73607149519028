
.CalendarClasses{
  height: 100%;
  padding-top: 47px;
  overflow: auto;
  
  .CalendarHeaders {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 10;
    background-color: white;
  }
}

.CalendarClasses::-webkit-scrollbar {
  -webkit-appearance: none;
}

.CalendarClasses::-webkit-scrollbar:vertical {
  width:0px;
}