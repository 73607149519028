
.CalendarRange{
  .calendar{
    &-month_header{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
      .is-prev{
        transform: rotate(-225deg);
      }
      .is-next{
        transform: rotate(-45deg);
      }

      &_title{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        top: -5px;
        color: #888C91;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &-header_button{
      width: 8px;
      height: 8px;
      background-color: transparent;
      padding: 0px;
      border: none;
      border-radius: 2px;
      border-right: 2.3px solid #D75F77;
      border-bottom: 2.3px solid #D75F77;
      outline: none;
    }
    &-days_of_week{
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      
      &_day{
        flex: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #888C91;
        font-weight: bold;
      }
    }

    &-week{
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      
      .is-current_month{
        color: #888C91;
        font-weight: bold;
      }
      .is-next_month, .is-prev_month{
        color: rgba(#888C91, 0.29);
      }
      .is-highlighted{
        background-color: #F0F4F8;
        color: #7494C7;
        font-weight: bold;

        &:first-child{
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-child{
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      .is-selected{
        background-color: #F0F4F8;
        color: #7494C7;
        font-weight: bold;
      }
      .is-start_selection{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .is-end_selection{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &-week-day{
      flex: 1;
      padding: 5px 0px;
      font-size: 10px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
    }
  }
}
