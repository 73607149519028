
.CalendarHeaders{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .holiday {
    background: #bdbdbd;
    padding: 1px 10px;
    color: white;
    border-radius: 2px;
    font-size: 10px;
    margin-top: 5px;
    ul {
      list-style: none;
      padding: 0;
    }
  }
  &.has-holidays {
    & + .holidays-wrapper {
      margin-top: 26px;
    }
  }
  &__empty{
    flex: 1;
    max-width: 90px;
    border: 1px solid #F1F2F4;
    padding: 13px 0px;
  }

  &__day{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F1F2F4;
    padding: 13px 0px;
    color: #333D48;
    font-weight: bold;
    text-transform: capitalize;
    flex-direction: column;

    &__alone{
      padding-left: 20px;
      align-items: flex-start;
    }
  }
}