
.ExamDetail{
  height: calc(100vh - 72px);
  padding: 13px 30px 50px;
  user-select: none;
  overflow: auto;
  &__breadcrumb {
    width: 100%;
  }
  .recharts-wrapper {
    float: right;
  }
  .gaugeChart {
    & > div {
      position: relative;
      .percent-text {
        fill: #505050;
        font-size: 3vw;
        font-weight: 600;
        line-height: 13px;
        text-align: center;
      }
      .needle {
        opacity: .3;
      }
    }
    label {
      color: #67758D;
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
      text-align: center;
      margin: auto;
      display: table;
    }
  }
  .ExamDetailCard{
    &__header{
      padding: 30px 40px;
      border-bottom: 2px solid #F1F2F4;
      &__level{
        width: 100px;
        display: inline-block;
        justify-content: center;
        margin-right: 10px;
      }
      &__title{
        margin-right: 15px;
        display: inline-block;
        h1{
          font-size: 20px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .ExamDetailCard__data{
    padding: 35px 40px;
    h2{
      font-size: 18px;
      margin-bottom: 1.2em;
    }
    &__list{
      list-style: none;
      padding-left: 0;
      dt,dd{
        display: inline;
      }
      dd{
        margin-left: 10px;
      }
    }
    &__obs__list{
      list-style: none;
      padding-left: 0;
      li{
        margin-bottom: 50px;
      }
      dt{
        margin-bottom: 10px;
      }
      dd{
        padding-right: 70px;
        font-size: 12px;
      }
    }
    &__results{
      margin-bottom: 50px;
      &__detail{
        &:before{
          content: '';
          display: inline-block;
          margin-right: 10px;
          width: 10px;
          height: 10px;
          border-radius: 4px;
          background-color: #60C56A;
        }
        &__co:before{
          background-color: #30B9D6;
        }
        &__eo:before{
          background-color: #9AC774;
        }
        &__ce:before{
          background-color: #6130D6;
        }
        &__ee:before{
          background-color: #D9C325;
        }
      }
    }
  }
  .SpinnerArea {
    min-height: calc(100vh - 262px);
  }
}