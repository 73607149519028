
.CardTable {
    .ant-table-thead > tr {
        border: 1px solid #F1F2F4;
        border-radius: 3px;
        background: #F1F2F4;
        .ant-table-column-title {
            font-weight: bold;
            font-size: 11px;
            color: rgba(0,0,0,0.87);
        }
    }
    .ant-table-tbody {
        td {
            color: rgba(0,0,0,0.87);
            font-size: 11px;
            line-height: 15px;
        }
    }
    .CircleGraph span {
        color: #333D48;
        font-family: Monaco, sans-serif;
        font-size: 9px;
        line-height: 15px;
        text-align: center;
        font-weight: normal;
    }
    button {
        background: transparent;
        border: none;
        outline: none;
        color: #D75F77;
        padding: 0;
        margin: 0;
        cursor: pointer;
        float: right;
    }
}
.replace-type-icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #888C91;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    &::after {
        background: #888C91;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
    }
    &.fraction {
        &::after {
            transform: translateX(50%);
        }
    }
}
.custom-popover {
    color: #333D48;
    font-weight: bold;
    font-size: 11px;
    padding: 0;
    margin: 0;
    .dot {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 10px;
    }
    li {
        list-style: none;
        &:not(:last-child) {
            margin-bottom: 10px
        }
    }
    label {
        color: #888C91;
        font-size: 11px;
        span {
            color: #D05757;
            font-size: 11px;
            margin-left: 10px;
        }
    }
    p {
        color: #333D48;
        font-weight: bold;
        font-size: 11px;
        margin: 0;
        &.regular {
            color: rgba(0,0,0,0.87);
        }
    }
}