.FormTariffs {
    .input-container {
        max-width: 500px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .ant-form-item-control-input {
            min-width: 340px;
        }
        .ant-btn {
            margin-left: 15px;
            min-width: 150px;
            background: #d75f77;
            border-color: #d75f77;
        }
    }
    .ant-tabs-content-holder {
        background: #f7f9fc;
    }
    .Tags {
        max-width: 650px;
        margin-bottom: 20px;
    }
}