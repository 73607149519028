.FilterTeachers{
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  &__title{
    color: #B3B4B4;
    font-size: 14px;
  }
  &__type{
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    div{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    min-width: 50vw;
    .FilterTeachers__type {
      margin-top: 15px;
      div:not(.ant-checkbox-group):not(.ant-radio-group) {
        margin-top: 15px;
      }
      label {
        color: #333D48;
        font-size: 11px;
        font-weight: bold;
        line-height: 15px;
      }
    }
    .Select {
      flex: 1;
      padding-right: 15px;
    }
    .InputCheckBox {
      min-width: 83px;
    }
  }
  .ant-checkbox-group {
    margin-top: 2px;
  }
  &__range{
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    min-width: 286px;
    background: white;
    position: absolute;
    left: 0;
    padding: 14px;
    transform: translateX(-50%);
    margin-left: 8px;
    top: -21px;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
    label {
      color: #B3B4B4;
      font-size: 11px;
      line-height: 15px;
      margin-bottom: 10px;
      width: 100%;
    }
    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      .ant-calendar-picker, .ant-time-picker {
        flex: 1;
        &:first-child {
          padding-right: 5px;
        }
        &:last-child {
          padding-left: 5px;
        }
        i {
          color: #D75F77;
        }
        .ant-calendar-range-picker-separator {
          color: transparent;
          &:before {
            content: '-';
            color: rgba(0, 0, 0, 0.27);
            margin: auto;
            width: 20px;
            text-align: center;
            display: block;
          }
        }
      }
    }
    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      .ant-radio-wrapper {
        margin-right: 0 !important;
      }
    }
    .InputCheckBox {
      margin-left: 0;
    }
  }
  button {
    align-self: flex-end;
  }
  .fake-select {
    max-width: 300px;
    div {
      margin: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: block;
      box-sizing: border-box;
      background-color: #FDFDFD;
      border: 1px solid #F1F2F4;
      border-top-width: 1.02px;
      border-radius: 4px;
      outline: none;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      user-select: none;
      padding: 4.5px 11px;
      span {
        max-width: 100%;
        overflow: hidden;
        color: #bfbfbf;
        line-height: 20px;
        text-align: left;
        text-overflow: ellipsis;
      }
      .anticon {
        color: #D75F77;
        font-size: 12px;
        float: right;
        margin-top: 5px;
      }
    }
  }
}
.without-ok-button {
  .ant-picker-ranges {
    display: none;
  }
}