.DangerButton{
  border: none;
  color: #D75F77;
  background: none;
  box-shadow: none;
  i{
    color: #D75F77;
    font-size: 15px;
  }
  span{
    position: relative;
    top: -3px;  
  }
}