.CalendarClasses .CalendarHeaders {
    &.holidays-wrapper {
        position: relative;
        z-index: 0;
        .CalendarHeaders__day {
            justify-content: initial;
            padding: 5px 10px;
            &.day:not(.selected-day) {
                display: none;
            }
        }
    }
    .holiday.client {
        background: #828282;
    }
}