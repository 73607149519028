.FilterClasses{
  flex: 1;
  padding-right: 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__container{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__search {
    width: 100%;
    min-width: calc(100% - 500px);
    input {
      line-height: 32px;
    }
  }
  &__checkboxes{
    display: flex;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__check{
      font-size: 11px;
      flex: 1;
      display: inline-flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      user-select: none;
      &:after{
        content: '';
        display: inline-block;
        min-width: 10px;
        min-height: 10px;
        border-radius: 50%;
      }
      &:before{
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      .ant-checkbox-inner{
        width: 20px;
        height: 20px;
        border: 2px solid #F1F2F4;
        border-radius: 4px;
        &:after{
          height: 13px;
          width: 6px;
          top: 7px;
          left: 3px;
        }
      }
      span:not(.ant-checkbox-inner):not(.ant-checkbox){
        top: -2.5px;
        line-height: 0;
        position: relative;
      }
    }
  }
  &__purple{
    &::after{
      background-color: #6130D6;
    }
  }
  &__pink{
    &::after{
      background-color: #D630C8;
    }
  }
  &__green{
    &::after{
      background-color: #5ED6B7;
    }
  }
  .Tags {
    padding-top: 20px;
  }
}

@media (max-width: 576px){
  .FilterClasses{
    flex-direction: column-reverse;

    &__container{
      flex-direction: column-reverse
    }

    &__checkboxes{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &__check{
        width: 100%;
        margin-left: 0px !important;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row-reverse;
        span:not(.ant-checkbox-inner):not(.ant-checkbox){
          flex: 1;
        }
      }
    }

  }
}