.confirm-edit-content-modal {

    .subtitle-modal {
        font-size: 16px;
        text-align: center;
        padding-bottom: 1rem;
    }

		.container-content {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;

			.section-modal {
				flex: 1;

				div {
					padding-left: 1rem;

					h1 {
						font-size: 14px; 
						font-weight: bold;
					}
					p {
						font-size: 12px;
					}
				}
			}

			.divider {
				height: auto;
			}
		}

}