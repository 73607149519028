.TicketForm {
    .ant-modal-content {
        padding: 0;
    }
    .ant-modal-body {
        padding: 0;
        .ant-row, .ant-col {
            display: flex;
            flex: 1;
        }
        .ant-col {
            flex-direction: column
        }
        .Modal {
            .head {
                background-color: #F1F2F4;
                border-radius: 6px;
                align-items: center;
                padding: 30px 20px;
                .ant-avatar, .ant-col:not(:last-child) {
                    margin-right: 20px;
                }
            }
            .body {
                padding: 30px;
            }
            label, p {
                color: rgba(0,0,0,0.87);
                font-size: 11px;
                margin-bottom: 10px;
                &.bold {
                    font-weight: bold;
                }
                span, i {
                    color: #888C91;
                }
                i {
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
            p {
                margin-top: 20px;
            }
            .Select {
                flex: 1;
                display: flex;
            }
        }
    }
    h3 {
        color: rgba(0,0,0,0.87);
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
    }
}