
.Layout{
  &.ant-layout {
    min-height: 100vh;
  }
  .top-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding: 20px 20px;
    .FixedAvatar {
      margin-left: 10px;
    }
  }
  &__content{
    left: 0px;
    width: calc(100vw - 80px);
    position: relative;
  }
  &__displace{
    left: 0;
    width: calc(100vw - 200px);
  }
  h4.ant-typography {
    position: absolute;
    z-index: 4;
    top: -47px;
  }
}

@media (max-width: 992px){
  .MyLayout{
    &__content{
      width: 100vw;
    }
  }
}
