
.AddressTable {
    .clipboard-wrapper {
        button {
            cursor: pointer;
            border: none;
            background: none;
        }
        .anticon {
            margin-left: 10px;
        }
    }
    .anticon-close-circle svg {
        fill: #809db3;
    }
}