.LockForm {
    width: calc(100% + 46px);
    margin-left: -23px;
    padding: 0px 69px;
    .range-picker {
        margin: auto;
        max-width: 300px;
        width: 100% !important;
    }
    h1 {
        color: #333D48;
        font-family: "Euphemia", sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 20px;
    }
    p {
        color: #333D48;
        display: flex;
        justify-content: flex-start;
        padding: 10px 20px;
        i {
            margin-right: 10px;
        }
    }
    .Select {
        flex: 1;
    }
    .ant-row {
        justify-content: space-between;
        display: flex;
        padding: 0 20px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &::before, &::after {
            content: initial;
        }
    }
    .repeat-group {
        padding: 20px 0;
        margin-bottom: 20px;
        &.fill {
            background-color: #FAFAFA;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 12px 0 rgba(0,0,0,0.1);
        }
        .ant-radio-wrapper:not(:last-child){
            margin-bottom: 20px;
        }
    }
    .MyButton{
        background-color: #D75F77;
        border: 1px solid #D75F77;
        border-radius: 15px;
        color: #D75F77;
        height: 35px;
        margin: 10px auto;
        margin-bottom: 0px;
        padding: 0px 69px;
        i{
          color: #D75F77;
          font-size: 15px;
        }
        &.ant-btn-primary {
          color: white;
          border: none;
          i {
            color: white;
          }
        }
        &:hover {
          background-color: #D75F77;
        }
        span{
          position: relative;
          top: -3px;  
        }
    }
}
