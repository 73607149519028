.warning-suspension {
    text-align: center;

    .icon-warning {
        font-size: 48px; 
        margin-bottom: 12px; 
        color: #ebaa4d;
    }

    .container-list {
        margin-bottom: 24px;

        section {
            margin-bottom: 24px;
        }

        .item-list {
            font-weight: bold; 
            margin-bottom: 4px; 
            text-decoration: none;
            color: black;
        }
    }

    .ant-btn+.ant-btn {
        margin-left: 20px;
    }
    .ant-btn-primary {
        background: #d75f77;
        border-color: #d75f77;
    }
    .inline {
        display: inline-block;
        width: calc(50% - 12px);
        margin-bottom: 0;
        &+.inline {
            margin-left: 24px;
        }
    }
}