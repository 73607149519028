
.ClassDetail{
  width: 220px;

  &__date{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    i{
      color: #B3B4B4;
      font-size: 12px;
      margin-right: 10px;
      cursor: pointer;
    }
    label{
      font-size: 12px;
      color: #333D48;
      cursor: pointer;
    }
  }

  &__map{
    width: calc(100% + 30px);
    height: 100px;
    position: relative;
    left: -15px;
  }

  &__event{
    display: flex;
    flex-direction: column;

    &__type{
      display: flex;
      flex-direction: row;
      align-items: center;

      i{
        color: #B3B4B4;
        font-size: 12px;
        margin-right: 10px;
      }
      span{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: rebeccapurple;
        margin-right: 13px;
      }
      label{
        color: #333D48;
        font-weight: bold;
      }
    }
    .ant-btn-sm {
      flex: 0;
    }
    &__info{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      label{
        margin-left: 20px;
      }
      i{
        color: #4D9CF4;
        font-size: 20px;
      }
    }

    &__list{
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-top: 10px;
      margin-bottom: 10px;

      span{
        color: #4D9CF4;
        width: 100%;
        text-align: center;
        position: relative;
        left: -10px;
        margin-top: 20px;
        cursor: pointer;
      }

      label{
        font-size: 12px;
        text-transform: capitalize;
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
    }
  }
}
.attendance {
  color: #333D48!important;
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px
}