
.ClasseDetail{
  padding: 30px;
  display: flex;
  flex-direction: column;
  &__breadcrumb {
    width: 100%;
  }

  &__info{
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  &__sidebar {
    .ant-card {
      margin-bottom: 20px;
    }
  }
  &__panel{
    padding-right: 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  .ant-col {
    min-height: inherit !important;
  }
}