
.FormGroupSchedule {
    padding: 13px 30px;
    .ant-form {
        & > .ant-row {
            max-width: 600px;
        }
        .week-config {
            .ant-col + .ant-col:not(.ant-form-item-control) {
                padding-left: 15px;
            }
            & + .week-config {
                .ant-form-item-label {
                    display: none;
                }
            }
        }
    }
    .ant-form-item-label {
        font-family: EuphemiaUCAS, sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #335267;
        
        label {
            align-items: flex-start;
        }
    }
    .ant-btn + .ant-btn {
        margin-left: 20px;
    }
    .ant-picker-range {
        width: 100%;
    }
    .sub-label {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: left;
        margin-bottom: 0px;
    }
}
.group-schedule-timepicker {
    .ant-picker-content {
        max-height: 112px;
    }
}