.ClasseEvent{
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  .status-chip {
    margin-top: 10px;
  }
  &__date{
    display: flex;
    flex-direction: row;

    &__icon{
      background-color: #F0F4F8;
      border-radius: 3px;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      i{
        color: #7494C7;
      }
    }

    &__date{
      font-size: 14px;
      color: #888C91;
      div:nth-child(1){
        font-weight: bold;
        color: #333D48;
      }
    }
  }
  &__event{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label{
      color: #B3B4B4;
      margin-bottom: 10px;
      font-size: 14px;
    }

    button{
      border: 1px solid #4D9CF4;
      border-radius: 15px;
      color: #4D9CF4;
      font-size: 12px;
    }
  }
}