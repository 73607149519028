.name-file {
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px
}

.label-list {
    color: #333D48;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    margin-right: 15px;
    text-align: left;
}

.title-principal {
    text-align: center;
}