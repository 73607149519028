.DynamicFilters {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  
    &__state{
      flex: 1;
      padding-right: 10px;
      box-sizing: border-box;
    }
    &__date{
      flex: 1;
      margin-right: 10px;
    }
    &__search{
      flex: 2;
      margin-right: 10px; 
      min-width: 300px
    }
    &__score{
      flex: 1;
    }
    .RangeContainer {
      display: flex;
      .Select {
        min-width: 110px;
      }
    }
    .Select, .ant-calendar-picker {
      min-width: auto;
      max-width: 100%;
      flex: 1;
      display: flex;
      &:not(:last-child) {
        margin-right: 10px;
      }
      width: calc(20% - 8px);
      margin-bottom: 15px;
      font-size: 11px;
    }
    .ant-col {
      display: flex;
      flex: 1;
    }
    &.loading {
        width: 100%;
        position: relative;
        .ant-col {
          opacity: 0;
        }
    }
    & > .ant-col {
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .AdvancedRange {
        min-width: 240px;
        max-width: 240px;
        margin-right: 10px;
    }
    .ant-calendar-picker {
        div {
          width: 100%;
        }
    }
    .Select {
        max-width: 350px;
        &:first-child {
          max-width: 140px;
        }
    }
}