.container-info {
    display: flex; 
    padding: 0px 8px; 
    align-items: center;
    user-select: none;
    -moz-user-select: none;
}

.ant-upload {
    padding: 0px !important;
    .ant-upload-hint {
        font-size: 12px !important;
    }
    .ant-upload-drag-icon {
        margin-bottom: 0px !important;
        padding: 12px 4px !important;
        svg {
            width: 24px;
            height: 24px;
        }
    }
}