.container-item {
  display: flex;
  justify-content: center;

  .list-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
  }

  .label-list {
    color: #333d48;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    margin-right: 15px;
    text-align: left;
  }

  .container-image-name {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .crop-image {
    height: 40px;
    width: 40px;
    margin-right: 8px;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
    }
  }
}
