.ClientDetail {
    padding: 13px 30px;
    .IconButton {
        float: right;
        margin-top: 0;
    }
    .ant-tabs {
        margin-top: 20px;
    }
    .Tags__container__brands__tag.clients {
        display: none;
    }
    .edit-btn {
        float: right;
    }
}