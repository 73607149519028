.MaterialsState {
    .ant-row {
        .ant-col {
            .ant-btn {
                margin: 20px auto;
                display: table;
                background: #809db3;
                border-color: #809db3;
            }
            h2 {
                text-align: center;
                font-size: 18px;
                color: #333d48;
            }
        }
    }
    .buttons-wrapper {
        margin: auto;
        display: table;
        vertical-align: middle;
        justify-content: center;
        text-align: center;
        align-items: center;
        align-content: center;
    }
}