.FormWrapper {
    .ant-form-item-label {
        font-family: EuphemiaUCAS, sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #335267;
    }
    .ant-btn+.ant-btn {
        margin-left: 20px;
    }
    .ant-btn-primary {
        background: #d75f77;
        border-color: #d75f77;
    }
    .inline {
        display: inline-block;
        width: calc(50% - 12px);
        margin-bottom: 0;
        &+.inline {
            margin-left: 24px;
        }
    }
}