.DeleteForm {
    min-width: 400px;
    text-align: center;
    h1 {
        color: #333D48;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        text-align: center;
        margin-bottom: 45px;
    }
    .MyButton {
        background-color: #D75F77;
    }
    .ant-row {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        margin-bottom: 20px;
        
        span {
            flex: 1;
        }
        label {
            color: #333D48;
            font-size: 11px;
            font-weight: bold;
            line-height: 15px;
            margin-right: 15px;
            min-width: 90px;
            text-align: right;
        }
        .list {
            line-height: 0;
            label {
                color: #333D48;
                font-size: 11px;
                line-height: 15px;
                font-weight: 100;
                display: block;
                text-align: left;
                margin-bottom: 1px;
            }
        }
        .message {
            color: #d75f77;
            font-size: 10px;
            margin: auto;
        }
    }
}

.row-evidence {
    margin-bottom: 0px !important;
    .select-evidence {
        width: 100% !important;
    }
}