.ant-modal-body {
    padding: 0px 24px !important;
}

.warning-conflict, .confirm-edit-schedule {
    .ant-btn+.ant-btn {
        margin-left: 20px;
    }
    .ant-btn-primary {
        background: #d75f77;
        border-color: #d75f77;
    }
    .inline {
        display: inline-block;
        width: calc(50% - 12px);
        margin-bottom: 0;
        &+.inline {
            margin-left: 24px;
        }
    }
}
