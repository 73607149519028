.IconButton {
  align-items: center;
  display: flex !important;
  .ant-btn-circle {
    background-color: #D75F77;
    color: white;
  }
  &.secondary {
    .ant-btn-circle {
      background-color: #809db3;
    }
  }
}