
.NoFoundPage{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1{
    width: 100%;
    max-width: 500px;
    text-align: center;
    color: #888;
    font-size: 6vmax;
    font-weight: lighter;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 3px solid #DDD;
  }
  h2{
    color: #888;
    font-size: 2vmax;
    font-weight: 200;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  p{
    max-width: 500px;
    text-align: center;
    font-size: 1.1vmax;
    font-weight: 300;
  }
}