.MyButton:not(.ant-btn-circle) {
  &.ant-btn {
    border: 1px solid #4D9CF4;
    border-radius: 15px;
    color: #4D9CF4;
    height: 35px;
    margin-top: 10px;
  }
  i {
    color: #4D9CF4;
    font-size: 15px;
  }
  &.ant-btn-primary {
    color: white;
    border: none;
    &:not([disabled]) {
      background: #d75f77;
    }
    &[disabled] {
      background: rgba(79, 79, 79, 0.26);
      color: rgba(0, 0, 0, 0.26);
    }
    i {
      color: white;
    }
  }
  &.ant-btn-link {
    border: none;
  }
  span:not(.anticon) {
    position: relative;
  }
}