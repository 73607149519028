.class-modal {
    h1 {
        text-align: center;
    }
    .ant-card-head-title, .ant-typography span {
        font-family: "EuphemiaUCAS-Bold", sans-serif;
    }
    .ant-card-type-inner {
        margin-bottom: 20px;
        .ant-form-item {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
    .cancelled {
        margin-top: 5px;
    }
}
.reposition-datepicker {
    .ant-picker-cell-inner {
        &.day-has-classes {
            background-color: #809DB3;
            color: white;
        }
        &.is-cancelled-date {
            color: #00000059;
            background-color: #d9d9d9;
        }
    }
    .ant-picker-footer {
        display: none;
    }
    .ant-space {
        padding: 10px 20px;
    }
}