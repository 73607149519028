
.TeacherDetail{
  padding: 13px 30px;
  .Tabs {
    max-width: initial
  }
  .Tabs, .CalendarCard {
    background: white;
    border-radius: 6px;
    .Card {
      box-shadow: none;
    }
    .TableContainer {
      button {
        margin-bottom: 10px;
      }
    }
  }
  .SpinnerArea {
    min-height: calc(100vh - 262px);
  }
  .weekCalendar {
    margin-bottom: 20px;
  }
  .ant-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    .ant-col {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  .more-info {
    margin-top: 35px;
    .ant-row:not(:last-child) {
      margin-bottom: 20px;
    }
    label {
      color: rgba(0,0,0,0.87);
      font-size: 13px;
      &.title {
        font-weight: bold;
      }
    }
  }
  &__inside {
    margin-bottom: 20px;
    .ant-card-body{
      height: 100%;
    }
    .Card {
      padding: 20px;
      border-radius: 6px;
      margin-bottom: 20px;
      .WeekTab {
        margin-bottom: 0;
      }
    }
    .detail {
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      position: relative;
      .MyButton {
        margin-top: 0;
      }
      .ant-btn-circle {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        transition: none;
        margin-top: -1px;
        &:hover, &:focus {
          color: #D75F77;
          border: none;
        }
      }
      &.opened {
        border-bottom: 1px solid #F1F2F4;
        padding-bottom: 15px;
        button {
          margin-top: -15px;
        }
      }
      label {
        color: rgba(0,0,0,0.87);
        font-size: 13px;
        line-height: 15px;
      }
      .user {
        flex-direction: row;
        display: flex;
        align-content: center;
        align-items: center;
        .ant-avatar {
          background-color: #7494C7;
          margin-right: 20px;
          color: white;
        }
        h1 {
          margin-right: 10px;
        }
        & + div {
          flex: 1;
          justify-content: space-between;
          display: flex;
          padding: 0 20px 0 45px;
          align-items: center;
        }
      }
    }
    .selection {
      margin-bottom: 20px;
      border-radius: 6px;
      .ant-card-body {
        display: flex;
        flex-wrap: wrap;
        .ant-checkbox-group {
          flex: 1;
          flex-direction: row;
          display: flex;
          flex-wrap: wrap;
          .ant-checkbox-wrapper {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 4px;
            cursor: pointer;
            .ant-checkbox + span {
              cursor: pointer;
              color: #333D48;
              font-size: 11px;
              line-height: 15px;
              white-space: nowrap;
            }
            &:hover {
              border-color: #F1F2F4;
              border-radius: 4px;
              background-color: #FBFBFB;
            }
            .color_id {
              display: inline-table;
              padding: 0;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin: 0 6px;
              transform: translateY(-1px);
            }
            a {
              color: #333D48;
              &:hover {
                color: #4D9CF4;
              }
            }
            .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: #4D9CF4;
                  border-color: #4D9CF4;
              }
            }
          }
        }
      }
    }
    h1 {
      color: rgba(0,0,0,0.87);
      font-family: "Euphemia", sans-serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }
  }
  .Modal {
    &Closable__body {
      h1 {
        color: #333D48;
        font-family: "Euphemia", sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }
    }
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
}