
.MenuDisplay{
  z-index: 4;
  &.ant-layout-sider {
    background-color: #334767;
  }
  &__items, .ant-layout-sider-trigger {
    background-color: #334767;
  }
  &__logo{
    height: 32px;
    margin: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img[alt=IconImage]{
      margin: 0px 10px;
      width: 2rem;
    }
    img[alt=IconText]{
      width: 10rem;
    }
  }
  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: transparent;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(0,0,0,.3);
  }
  li {
    a {
      line-height: 35px;
    }
  }
}

@media (max-width: 992px){
  .MenuDisplay{
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;

    &__mobileRules{
      flex: 0 0 200px !important;
      max-width: 200px !important;
      min-width: 200px !important;
      width: 200px !important;
    }

    &__items{
      position: relative;
      left: -80px;
      transition-duration: 0.3s;
    }

    &__itemsRules{
      transition-duration: 0.3s;
      left: 0px !important;
    }
  }
  .ant-layout-sider-trigger{
    display: none;
  }
}
.ant-tooltip-inner {
  a {
    color: white;
  }
  .anticon {
    display: none;
  }
}