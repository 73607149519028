
.ResetDisplay{
  height: 100%;
  background-color: white;
  background-color: #E0E7FF;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container{
    width: 100%;
    max-width: 520px;
    padding: 10px;
    box-sizing: border-box;

    img{
      max-width: 71px;
      margin-top: 40px;
    }
    a{
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }

  &__form{
    max-width: 320px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &__row{
      text-align: center;
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
}