.Select {
  display: flex;
  flex-direction: column;
  label{
    margin-bottom: 10px;
    color: #333D48;
    margin-top: 15px;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
  }
  i {
    color: #D75F77;
  }
}

.ant-select-selection {
  border: 1px solid #F1F2F4;
  border-radius: 3px;
  background-color: #FDFDFD;
  &-selected-value {
      color: #333D48;
      font-size: 11px;
      line-height: 28px;
  }
}
.ant-select-arrow {
  color: #D75F77 !important;
}