.TeacherPage{
  padding: 0 30px 20px;
  display: flex;
  flex-direction: column;
  .IconButton {
    float: right;
  }
  .TagsContainer {
    display: flex;
    padding-bottom: 20px;
    align-items: center;
    .MyButton {
      margin-top: 0;
    }
  }
  &__row{
    flex: 1;
  }

  &__col{
    height: 100%;
  }

  &__card{
    height: 100%;
  }
  .teachers-detail {
    margin-top: 0;
    margin-right: 0;
    & + .Tags__container {
      margin-left: 20px;
    }
  }
}