.ant-calendar-picker {
    min-width: 240px;
    max-width: 240px;
    input {
        text-align: center;
        background-color: #FDFDFD;
        border: 1px solid #F1F2F4;
        border-radius: 3px;
        height: 30px;
        color: #333D48;
        font-size: 11px;
        line-height: 15px;
    }
    .anticon {
        color: #D75F77;
    }
    .ant-calendar-range-picker-input {
        &:nth-child(1) {
            text-align: right;
        }
        &:nth-child(3) {
            text-align: left;
        }
    }
}
.week-picker {
    .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date, .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
        color: rgba(0, 0, 0, 0.5);
    }
    .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week .ant-calendar-today .ant-calendar-date {
        color: rgba(0, 0, 0, 0.5);
    }
}
.ant-calendar-date:hover {
    background: rgba(116, 148, 199, 0.2);
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
    background: rgba(116, 148, 199, 0.2);
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    background: rgba(116, 148, 199, .2);
    .ant-calendar-today {
        .ant-calendar-date {
            color: white;
        }
    }
    .ant-calendar-date {
        border-color: transparent;
    }
    td {
        &:nth-child(2), &:nth-child(8) {
            position: relative;
            &::before {
                position: absolute;
                top: 0;
                left: 50%;
                background: #7494c7 !important;
                width: 30px;
                height: 30px;
                content: "";
                z-index: 1;
                transform: translateX(-50%);
            }
            &::after {
                position: absolute;
                top: 0;
                background: white !important;
                width: 30px;
                height: 30px;
                content: "";
                z-index: 0;

            }
            .ant-calendar-date {
                color: white;
                z-index: 2;
                position: relative;
            }
        }
        &:nth-child(2) {
            &::after {
                left: -13px;
            }
        }
        &:nth-child(8) {
            &::after {
                right: -13px;
            }

        }
    }
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
    background: rgba(116, 148, 199, .2);
    & + div {
        color: white;
    }
}
.ant-calendar-today .ant-calendar-date {
    color: #7494c7;
    border-color: #7494c7;
}
.ant-calendar-week-number-cell, .ant-calendar-week-number-header {
    display: none;
}
.ant-calendar-month-panel-cell {
    .ant-calendar-month-panel-month {
        height: 38px;
        line-height: 38px;
        min-width: 98%;
        padding: 0;
    }
}
.ant-calendar-month-panel-selected-cell {
    .ant-calendar-month-panel-month {
        background-color: #7494C7;
    }
    .ant-calendar-month-panel-month:hover {
        background-color: #6688bd;
    }
}
.ant-calendar-month-panel-month:hover {
    background: #f3f3f3;
}
.ant-calendar-header a:hover {
    color: rgba(0, 0, 0, 0.5);
}
.trimester-picker {
    .ant-picker-cell {
        height: 38px;
        &:before {
            content: initial;
        }
    }
    .custom-month {
        padding: 8px 0;
        text-transform: capitalize;
        border-radius: 3px;
        margin: 0 3px;
        &.selected-highlight {
            background-color: #7494C7;
            color: white;
        }
        &.highlight {
            background: #f3f3f3;
            color: rgba(0, 0, 0, 0.65);
        }
    }
}
.range-picker {
    .ant-input {
        background-color: #FDFDFD;
        border: 1px solid #F1F2F4;
        border-radius: 3px;
        height: 30px;
        line-height: 19px;
    }
    input {
        background-color: #FDFDFD;
        border: none;
        height: auto;
    }
    .ant-calendar-selected-date .ant-calendar-date, .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-selected-end-date .ant-calendar-date {
        background: #7494c7 !important;
    }
}