.Tags {
  display: flex;
  flex: 1;
  align-items: center;
  &.gray {
    background: #f7f9fc;
    padding: 15px;
    min-height: 54px;
    .Tags__container__brands__tag {
      border: 1px solid #809db3;
      background: white;
      color: #809db3;
      &:after {
          background: #809db3;
      }
      &:not(.saved) {
          border: none;
          background: #d75f77;
          color: white;
          &:after {
              background: transparent;
          }
      }
    }
  }
  &__container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    &.align-right {
      justify-content: flex-end;
    }
    &__brands {
      &__tag{
        background-color: #E8E8E8;
        border-radius: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        white-space: nowrap;
        padding: 2px 8px;
        flex: 0;
        position: relative;
        cursor: pointer;
        user-select: none;
        span:not(.anticon) {
          line-height: 16px;
          font-size: 11px;
          margin-top: -1.5px;
        }
        .anticon {
          margin-right: 5px;
        }
        &:after {
          content: '';
          margin-left: 5px;
          color: white;
          background-color: #B3B4B4;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          font-weight: bold;
          border-radius: 7px;
          position: relative;
          top: 0;
          right: -4px;
          text-align: center;
        }
        &:before {
          content: 'x';
          color: white;
          font-size: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 9px;
          line-height: 0;
          z-index: 1;
          margin-top: -1.5px;
        }
      }
    }
  }
}