.IncompleteGroupList {
    .MyButton.activate-btn {
        margin-top: 0;
    }
    .anticon-check-circle {
        color: #e4e9f2;
        &.active {
            color: #6fcf97;
        }
    }
}