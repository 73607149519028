.RepositionSidebar {
    .MyButton:not(.ant-btn-circle).ant-btn {
        margin-top: 0;
    }
    &__drawer {
        .ant-list-vertical .ant-list-item-meta-title, .ant-list-vertical .ant-list-item-meta  {
            margin-bottom: 0;
        }
        .ant-list-item-meta-title, .ant-typography span {
            font-family: "EuphemiaUCAS-Bold", sans-serif;
        }
    }
}