.ConfirmationModal {
    .ant-modal-body {
        & > .ant-row {
            margin-bottom: 10px;
            .ant-col {
                &:first-child {
                    text-align: right;
                    padding-right: 10px;
                }
            }
        }
    }
    .anticon-exclamation-circle {
        color: #ebaa4d;
        margin: auto;
        font-size: 50px;
        display: block;
    }
    h1 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
        text-align: center;
    }
    .ant-modal-content {
        padding-bottom: 0;    
    }
    .ant-modal-footer {
        text-align: center;
        display: block;
    }
    ul {
        max-width: 265px;
        max-height: 250px;
        overflow: auto;
        margin: auto;
        list-style: none;
        padding: 0;
        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: inherit;
            }
        }
    }
}