.CalendarTab{
  padding: 20px;
  box-sizing: border-box;

  .ant-radio-group {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #EAECF8;
    min-width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-radio-button-wrapper{
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .ant-radio-button-wrapper-checked{
      border: none;
      color: black;
      font-weight: 500;
      background-color: white;
      border-radius: 4px;
    }

    label{
      flex: 1;
      justify-content: center;
      align-items: center;
    }    
  }
  .calendar-days_of_week, .calendar-week {
    .is-weekend:last-child {
      display: none;
    }
  }
}