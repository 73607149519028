
.ClassesPage{
  padding: 13px 30px;
  user-select: none;
  &__inside{
    padding-right: 13px;
    height: calc(100vh - 165px);

    .Card{
      height: 100%;
    }

    .ant-card-body{
      height: 100%;
      overflow: auto;
    }

    &__filter{
      display: flex;
      flex-direction: row;
      padding: 25px;
      &__filters{
        align-self: center;
        flex: 1;
      }
      &__create{
        button{
          border-radius: 15px;
          color: #4D9CF4;
          font-size: 12px;
          margin-top: 0;
          display: flex;
          align-content: center;
          align-items: center;
          span {
            top: -2px;
          }
          i {
            top: -1px;
            position: relative;
          }
        }
      }
    }

    &__calendar{
      height: calc(100% - 126px);
      position: relative;
    }
  }
}

@media(max-width: 992px){
  .ClassesPage{
    padding: 0px;
    padding-top: 50px;

    &__title{
      display: none;
    }

    &__inside{
      padding: 0px;
      border: 1px solid red;
      height: calc(86vh);
      &__filter{
        display: none;
      }

      &__calendar{
        height: calc(100%);
      }

      .Card{
        box-shadow: none;
      }
    }
  }
}