.TableTeachers{
  overflow: auto;
  padding: 20px;
  .Tags {
    justify-content: flex-start;
    &__brands {
      display: flex;
      overflow: auto;
      &__tag {
        margin: 5px;
      }
    }
  }
  .star-ratings {
    width: 100%;
  }
  .ant-table-body {
    max-height: calc(100vh - 285px);
    overflow: auto;
    tr {
      cursor: pointer;
      .ant-checkbox {
        width: 55px;
        height: 45px;
        &-checked::after {
          opacity: 0;
          animation: none;
        }
      }
    }
  }
  .ant-table-selection-column {
    padding: 0;
    .ant-checkbox-inner {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .ant-pagination {
    margin: 16px 0 0;
    float: right;
  }
}