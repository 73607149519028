.FixedAvatar {
  top: 0;
  left: 0;
  background: #eaecf8;
  padding: 20px 20px;
  z-index: 3;
  display: flex;
  flex-direction: row-reverse;
  &__block{
    text-transform: capitalize;
    cursor: pointer;
    span{
      margin-right: 10px;
      margin-left: 10px;
    }
    > i{
      margin-left: 10px;
      color: #D75F77;
      font-size: 16px;
      font-weight: bolder;
    }
  }
}