.Tickets{
  height: calc(100vh - 72px);
  padding: 0 30px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .ant-table-body {
    font-size: 12px;
  }
  .ant-table-thead {
    th:first-child {
      text-align: center;
    }
  }
  .from-now {
    opacity: .5;
    display: block;
    padding-left: 20px;
    font-size: 10px;
  }
  .RangeContainer {
    min-width: calc(100vw - 220px);
  }
  .status {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    padding: 3px;
  }
  .priority {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    i {
      margin-right: 10px;
      font-size: 13px;
    }
  }
  .indicator {
    border: 1px solid #F1F2F4;
    border-radius: 10px;
    color: #333D48;
    font-weight: bold;
    text-align: center;
    display: table;
    margin: auto;
    padding: 20px;
    span {
      font-size: 20px;
      display: block;
    }
    label {
      font-size: 11px;
    }
  }
  & > .ant-row { 
    margin-bottom: 20px;
  }
  .ant-row {
    flex: 0;
    display: flex;
    .ant-col {
      flex: 1;
      & > .ant-col {
        flex: 0;
        .flex {
          margin-right: 10px;
        }
      }
    }
    .Card {
      box-shadow: none;
      .ant-card-head {
        padding: 0;
        border: none;
        .ant-card-head-title {
          padding: 0 0 17px;
        }
      }
      .ant-card-body {
        padding: 20px;
      }
    }
  }
}