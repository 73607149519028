.Tabs {
    .ant-tabs-ink-bar {
      background-color: #D75F77;
      height: 4px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #333D48;
    }
    .ant-tabs-nav .ant-tabs-tab {
      padding: 20px 16px;
    }
}