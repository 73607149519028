.SearchTeachers{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 20px;
  .ant-input {
    line-height: 32px;
  }
  .certain-category-search-wrapper {
    min-width: 300px;
  }
  button.Popover {
    margin-left: 20px;
    position: relative;
    top: 0;
    background: #4D9CF4;
    color: white !important;
    padding: 0 8px;
    outline: none !important;
    border: none !important;
    &:after {
      content: "filter_list" !important;
      font-family: "Material Icons", sans-serif;
      font-weight: normal;
      font-style: normal;
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-rendering: optimizeLegibility;
      font-feature-settings: 'liga';
      color: white;
      position: absolute;
      left: 7px !important;
      top: 9.5px !important;
      font-size: 13px;
    }
    &:hover, &:focus, &:active, &.active {
      background: #4D9CF4;
      span {
        color: white !important;
      }
    }
    .anticon {
      opacity: 0;
    }
    span {
      margin-left: 1px !important;
    }
    .anticon, span {
      top: -3px;
      position: relative;
    }
  }
}