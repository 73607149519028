
.WeekTab{
  margin-bottom: 20px;
  button{
    margin-right: 10px;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    display: inline-flex;
    max-height: 33px;
    max-width: 33px;
    border-radius: 50%;
    margin-top: 0;
    border: none;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.08);
    .anticon {
      color: #D75F77;
      position: relative;
      top: -.5px;
    }
  }
  label{
    margin-left: 5px;
    text-transform: capitalize;
    font-family: "Euphemia", sans-serif;
  }
}