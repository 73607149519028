.spinner {
    .blobs {
        -webkit-filter: url("#goo");
        filter: url("#goo");
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
    }
    .blob {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        &:nth-child(1) {
            background-color: #FFC13B;
            animation: blob-left-anim cubic-bezier(0.770, 0.000, 0.175, 1.000) 2s infinite;
        }
        &:nth-child(2) {
            background-color: #D75F77;
            animation: blob-right-anim cubic-bezier(0.770, 0.000, 0.175, 1.000) 2s infinite;
        }
    }
}
.center.ant-spin-spinning {
    position: absolute;
    top:50%;
    left:0;
    right:0;
    margin: auto;
    transform: translateY(-50%);
    .ant-spin-dot-item {
        background-color: #335267;
    }
}

@keyframes blob-left-anim {
  0% {transform:scale(1.0) translate(0, 0);}
  33% {transform:scale(0.5) translate(50px, 0);}
  66% {transform:scale(0.8) translate(0, 0);}
  100% {transform:scale(1.0) translate(0, 0);}
}

@keyframes blob-right-anim {
  0% {transform:scale(1.0) translate(0, 0);}
  33% {transform:scale(0.5) translate(-50px, 0);}
  66% {transform:scale(0.8) translate(0, 0);}
  100% {transform:scale(1.0) translate(0, 0);}
}

