.Modal{
    .ant-modal-header, .ant-modal-footer {
        border: none;
    }
    .ant-modal-header {
        .ant-modal-title {
            color: #333D48;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
        }
    } 
    .ant-modal-footer {
        text-align: center;
        .ant-btn {
            border: 1px solid #D75F77;
            border-radius: 15px;
            span {
                color: #D75F77;
                font-weight: bold;
                font-size: 11px;
                line-height: 21px;
            }
            &-primary {
                background: #D75F77;
                span {
                    color: white;
                }
            }
        }
    }
}