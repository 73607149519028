
.ClassDetailCard{
  display: flex;
  flex-direction: row;
  min-height: 240px;
  .invalid-schedule-label {
    color: rgb(215, 95, 119);
    margin-top: -6px;
    display: table;
  }
  .ant-btn-circle {
    background-color: #4D9CF4;
    border: none;
    border-color: #4D9CF4;
    font-size: 20px;
    align-self: center;
    margin-left: 20px;
    padding: 0;
  }
  a {
    color: inherit;
  }
  &.is_virtual_class {
    .ClassDetailCard__data{
      flex: 1;
      &__data{
        max-width: 100%;
      }
    }
  }
  &__data{
    flex: 8;
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;

    &__level{
      width: 100px;
      display: flex;
      justify-content: center;
      margin-right: 10px;
    }
    &__data{
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      max-width: 600px;

      &__title{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        &__name{
          margin-right: 15px;
          h1{
            font-size: 20px;
            margin-bottom: 0px;
          }
          h2{
            font-size: 14px;
            margin-top: 0px;
          }
        }

        &__score{
          display: flex;
          align-items: center;
        }
      }
      &__more{
        display: flex;
        flex-direction: row;

        &__section{
          color: #333D48;
          flex: 1;
          justify-content: space-between;

          div{
            margin-bottom: 10px;
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;

            i{
              font-size: 16px;
              margin-right: 10px;
            }
            .Flags {
              margin-bottom: 0;
              img {
                width: 20px;
                height: 20px;
                margin-left: 10px;
              }
            }
            &.edit {
              cursor: pointer;

              &:hover {
                background: rgba(128, 128, 128, 0.03);
              }
              .button {
                color: #4D9CF4;
                font-size: 16px;
                transform: translateY(-50%);
                cursor: pointer;
                margin: 0px 20px;
              }
            }
          }
        }
      }
    }
  }
  &__map{
    flex: 4;
    min-height: 240px;
    max-width: 400px;
    background-color: gray;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    overflow: hidden;
    & > img {
      width: 100%;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }
}
.dropdown-menu {
  ul {
    list-style: none;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

    li {
      clear: both;
      margin: 0;
      padding: 5px 12px;
      color: #000000d9;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      transition: all .3s;
    }

    li:hover {
      background-color: #fafafad9;
    }

    .spacing {
      padding: 0px;
      margin: 0px;
    }
  }
}