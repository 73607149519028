
.ClientsPage {
    padding: 13px 30px;
    .IconButton {
        float: right;
        margin-top: 0;
    }
    .ClientList {
        margin-top: 20px;
    }
}