.edit-registration {
  h1 {
    color: #333d48;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    margin-bottom: 45px;
  }
  .ant-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-bottom: 20px;
    span {
      flex: 1;
    }
    label {
      color: #333d48;
      font-size: 11px;
      font-weight: bold;
      line-height: 15px;
      margin-right: 15px;
      min-width: 90px;
      text-align: right;
    }
  }
  .container-action {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
