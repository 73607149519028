.edit-schedule-modal {
    text-align: center;

    .container-form {
        text-align: start;
        margin-bottom: 24px;

        .date-picker {
            width: 100%;
        }

        .container-field-dynamic {
            max-height: 160px;
            overflow: auto;
            margin-bottom: 12px;
        }
    }
}