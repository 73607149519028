
.NewTeacher {
    padding: 13px;
    .ant-form-item, .name-row {
        max-width: 600px;
        flex: 1;
    }
    .ant-form-item-label {
        font-family: EuphemiaUCAS, sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #335267;
    }
    .ant-btn + .ant-btn {
        margin-left: 20px;
    }
    .name-row {
        align-items: end;
        & > .ant-col {
            flex: 1;
            align-self: flex-end;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}