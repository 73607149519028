$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Euphemia';
  src: url('./assets/fonts/Euphemia_UCAS_Bold_2.6.6.ttf') format('truetype')
       url('./assets/fonts/euphemia_ucas_bold_2.6.6-webfont.woff2') format('woff2'),
       url('./assets/fonts/euphemia_ucas_bold_2.6.6-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Euphemia';
  src: url('./assets/fonts/Euphemia_UCAS_Italic_2.6.6.ttf') format('truetype')
       url('./assets/fonts/euphemia_ucas_italic_2.6.6-webfont.woff2') format('woff2'),
       url('./assets/fonts/euphemia_ucas_italic_2.6.6-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Euphemia';
  src: url('./assets/fonts/Euphemia_UCAS_Regular_2.6.6.ttf') format('truetype'),
       url('./assets/fonts/euphemia_ucas_regular_2.6.6-webfont.woff2') format('woff2'),
       url('./assets/fonts/euphemia_ucas_regular_2.6.6-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*{
  font-family: 'Euphemia', "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  width: 100%;
  background-color: #EEE;
}


.ant-select {
  &-dropdown {
    min-width: 240px; 
  }
  &-selection--single {
    height: 30px;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #B3B4B4;
  &::after {
    background-color: #D75F77;
  }
}

.ant-popover {
  &-inner {
      padding-top: 10px;
  }
  &-title {
    border: none;
    color: #333D48;
    font-weight: bold;
    font-size: 11px;
  }
}

.ant-switch-checked {
  background-color: #D75F77;
}

.ant-notification {
  .ant-notification-notice-description, .ant-notification-notice-message {
    margin-left: 40px;
    color: white;
  }
  .ant-notification-notice {
    background: #334767;
  }
  .anticon.ant-notification-notice-icon-warning {
    color: #ffbd3a;
  }
  .ant-notification-close-icon {
    color: white;
    font-size: 14px;
  }
}

.ant-modal-body {
  padding-top: 0px 24px;
}
.status-chip {
  text-align: center;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  padding: 3px;
  &.cancelled {
    background-color: #F1C0CD;
    color: #C04C61;
  }
}
.ant-modal-root {
  z-index: 1050;
  position: relative;
}
.ant-modal-content {
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.empty-text {
  color: '#333333';
}

.ant-empty p {
  display: none;
}

.ant-empty::after {
  content: 'Sin información';
}

.CalendarRange .calendar-month_header_title {
  text-transform: capitalize;
}

.CalendarRange .calendar-header_button {
  cursor: pointer;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
}

.ant-pagination-item-link {
  i {
    vertical-align: 0;
  }
}

.ant-pagination-item{
  margin-right: 0px;
  border: none;
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: #D75F77;
  border-color: #D75F77;
}

.ant-pagination-item-active a, .ant-pagination-item-link i, .ant-pagination-item:hover a {
  color: #D75F77;
}

.ant-pagination-next {
  margin-left: 8px;
}

.icon-button-custon {
  display: flex; 
  justify-content: center; 
  font-size: inherit;
}