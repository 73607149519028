
.FormGroupInfo {
    padding: 13px 30px;
    .ant-form-item {
        max-width: 600px;
    }
    .ant-form-item-label {
        font-family: EuphemiaUCAS, sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #335267;
    }
    .ant-btn + .ant-btn {
        margin-left: 20px;
    }
    .ant-select-loading {
        .ant-select-selection-overflow-item {
            display: none;
        }
    }
}