.CircleGraph{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__circle{
    border: 3px solid #D8D8D8;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
  }

  svg{
    position: absolute;
    top: -1.5px;
    left: -1.6px;
  }

  span{
    position: relative;
    color: #333D48;
    font-weight: bold;
  }
}