.ReportModal {
    .ant-modal-body {
        .ant-row, .ant-col {
            display: flex;
            flex: 1;
        }
        .ant-col {
            flex-direction: column
        }
        .ant-row + .ant-row {
            margin-top: 20px;
        }
        .MyButton {
            margin: auto;
        }
    }
    .Select {
        flex: 1;
        margin-right: 20px;
    }
    h3 {
        color: rgba(0,0,0,0.87);
        font-family: "EuphemiaUCAS-Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }
}