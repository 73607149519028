
.CalendarClass{
  height: 25px;
  width: 100%;
  border: 1px solid #D7D9DC;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0);
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-duration: 0.3s;
  cursor: pointer;
  position: relative;

  &:hover{
    background-color: #EAECF8;
    transition-duration: 0.3s;
  }

  &__i{
    width: 4px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 100%;
  }
  label{
    width: 100%;
    max-width: 7vw;
    padding: 0px 4px;
    box-sizing: border-box;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .Flags{
    position: absolute;
    right: 0px;
    height: 15px;
    width: 15px;
    img {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .Suspension{
    margin-right: 5px;
    position: absolute;
    right: 0px;
    font-size: 15px;
    width: 15px;
    height: 15px;
    color: #d75f77;
  }
}

.CalendarClassDay{
  width: 100%;
  max-width: 19.2%;
  border: 1px solid #D7D9DC;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
  position: relative;
  padding: 0px 10px;
  box-sizing: border-box;
  padding-right: 25px;
  &__i{
    position: absolute;
    height: 100%;
    width: 4px;
    left: 0px;
    background-color: green;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  img{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  a{
    display: flex;
    flex-direction: column;
    color: #888C91;
    label{
      font-size: 10px;
      cursor: pointer;
      margin-bottom: 2px;
    }
  }
}

.isObservation{
  background-color: #5ED6B7;
}
.isPresentation{
  background-color: #6130D6;
}
.isTrial{
  background-color: #D630C8;
}

@media (max-width: 1200px){
  .CalendarClassDay{
    max-width: 32%;
  }
}

@media (max-width: 992px){
  .CalendarClassDay{
    max-width: 49%;
  }
}

@media (max-width: 768px){
  .CalendarClassDay{
    max-width: 768px;
  }
}

