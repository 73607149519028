
.InputCheckBox{
  min-width: 120px;
  font-size: 11px;
  .ant-checkbox-inner{
    width: 20px;
    height: 20px;
    border: 2px solid #F1F2F4;
    border-radius: 3px;
    &:after{
      height: 13px;
      width: 6px;
      top: 7px;
      left: 3px;
    }
  }
}