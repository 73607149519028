.TableContainer {
  padding: 20px;
  button {
    background: transparent;
    border: none;
    outline: none;
    color: #D75F77;
    padding: 0;
    margin: 0;
    cursor: pointer;
    float: right;
  }
}