.GroupCalendar {
    padding: 20px;
    .download-button {
        .ant-btn {
            margin-left: 10px;
        }
    }
    .head {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
    }
    .group-event {
        font-family: Roboto, sans-serif;
        font-size: 8.7px;
        font-weight: 500;
        text-align: left;
        color: #fff;
        padding: 4.1px 1.7px;
        border-radius: 1.4px;
        line-height: 7px;
        &.holiday, &.holiday_extra {
            height: 100%;
            background-color: #bdbdbd;
        }
        &.start_plan {
            background-color: #6fcf97;
        }
        &.feedback {
            background-color: #bb6bd9;
        }
        &.exam {
            background-color: #f2994a;
        }
        &.start_sublevel {
            background-color: #2f80ed;
        }
        &.change_level {
            background-color: #f2c94c;
        }
        &.special_exam {
            background-color: #56ccf2;
        }
    }
    .calendars {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .ant-picker-calendar-header {
            display: none;
        }
        .calendar-container {
            flex: .5;
            min-width: calc(50% - 30px);
            max-width: calc(50% - 30px);
            box-shadow: 2.7px 2.7px 10.9px 0 rgba(0, 0, 0, 0.05);
            padding: 15px;
            margin: 10px;
            &:not(.current-month) {
                .ant-picker-cell-selected {
                    .ant-picker-calendar-date {
                        background: initial;
                    }
                    .ant-picker-calendar-date-value {
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
            }
            .header-month {
                font-family: Roboto, sans-serif;
                font-size: 12px;
                text-align: left;
                color: #232a3e;
                b {
                    font-family: Roboto, sans-serif;
                    font-size: 14px;
                    font-weight: 900;
                    color: #232a3e;
                    text-transform: capitalize;
                    margin-right: 5px;
                }
            }
        }
        .ant-picker-calendar {
            flex: 1;
            .ant-picker-calendar-date {
                padding: 5px 4px 2px;
            }
            .ant-picker-panel .ant-picker-calendar-date-content {
                height: 25px;
                overflow: hidden;
                position: relative;
            }
            thead {
                tr {
                    th {
                        text-transform: capitalize;
                    }
                }
            }
        }
        .ant-picker-calendar-full {
            .ant-picker-panel {
                .ant-picker-calendar-date {
                    margin: 0;
                }
                .ant-picker-calendar-date-value {
                    font-size: 8px;
                    font-weight: 500;
                }
            }
        }
    }
    .labels {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        label {
            font-family: EuphemiaUCAS, sans-serif;
            font-size: 12px;
            color: #828282;
            margin-right: 20px; 
            display: flex;
            line-height: 10px;
            white-space: nowrap;
            margin-bottom: 30px;
            &::before {
                content: "";
                width: 12px;
                height: 12px;
                margin-right: 5px;
                display: inline-block;
            }
            &.start_plan {
                &::before {
                    background-color: #6fcf97;
                }
            }
            &.start_workshop {
                &::before {
                    background-color: #ff007f;
                }
            }
            &.feedback {
                &::before {
                    background-color: #bb6bd9;
                }
            }
            &.exam {
                &::before {
                    background-color: #f2994a;
                }
            }
            &.start_sublevel {
                &::before {
                    background-color: #2f80ed;
                }
            }
            &.change_level {
                &::before {
                    background-color: #f2c94c;
                }
            }
            &.special_exam {
                &::before {
                    background-color: #56ccf2;
                }
            }
            &.holiday, &.holiday_extra {
                &::before {
                    background-color: #bdbdbd;
                }
            }
        }
    }
}
@media print {
    .GroupCalendar {
        .labels label::before, .group-event {
            -webkit-print-color-adjust: exact; 
        }
    }
}